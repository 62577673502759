import { ToastContainer, ToastContainerProps } from "react-toastify";

export const toastOptions: ToastContainerProps = {
  position: "bottom-center",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};

const StyledToastContainer = () => {
  return <ToastContainer {...toastOptions} />;
};

export default StyledToastContainer;
