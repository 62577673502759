import { Component } from "react";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    if (import.meta.env.VITE_APP_ARG === "prd") {
      Sentry.captureException(error, { extra: info });
      this.setState({ hasError: true });
    }
  }

  render() {
    if (this.state.hasError) {
      return <Navigate to="/" replace />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
