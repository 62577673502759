export const getImgUrl = (url: string): string => {
  if (!url) return "";
  if (url.startsWith("http")) return url;
  return `https://images.moreden.co.kr/img/${url}`;
};

export const getStaticImgUrl = (url: string): string =>
  `https://moreden-img.s3.ap-northeast-2.amazonaws.com/static/${url}`;

export const getResizedImgUrl = (
  url: string,
  width?: string | number,
  height?: string | number,
): string => {
  const format = "webp";
  if (!url) {
    return "";
  }
  if (url) {
    if (url.indexOf("blob:") === 0 || url.indexOf("data:") === 0) {
      return url;
    }
    if (url.indexOf(".svg") > -1) {
      return url;
    }
  }
  let resizerEndpoint = "";

  if (width || height) {
    resizerEndpoint = import.meta.env.VITE_APP_PUBLIC_IMAGE_CDN_URL;
  }
  if (width && width !== "auto" && height && height !== "auto") {
    resizerEndpoint += `/crop/${width}x${height}/${format}/`;
  } else if (width && width !== "auto") {
    resizerEndpoint += `/width/${width}/${format}/`;
  } else if (height && height !== "auto") {
    resizerEndpoint += `/height/${height}/${format}/`;
  }
  // FIXME: CSS.escape() 로 대체할 수 있을 것 같습니다.
  const urlEncoded = encodeURI(url)
    .replace(/%25/g, "%") // '%25' 를 브라우저에서 '% + 25' 로 해석하는 경우 있음
    .replace("(", "%28") // CSS background-image: url(...) 안에서 괄호 때문에 escape 되는 문제
    .replace(")", "%29"); // CSS background-image: url(...) 안에서 괄호 때문에 escape 되는 문제

  return url ? resizerEndpoint + urlEncoded : "";
};
