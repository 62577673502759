import { api, dttm, cls, notiIcon } from "../../utils/util";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { alarmState } from "../../state";
import analytics from "../../firebase";
import { logEvent } from "firebase/analytics";

const useOutsideAlerter = (ref, alarm, setAlarm) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setAlarm({
        noti: alarm.noti.map((item) => ({ ...item, noti_status: "read" })),
        load: true,
        popup: false,
      });
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });
};

const AlarmHeader = ({ children }) => {
  const [alarm, setAlarm] = useRecoilState(alarmState);
  const unread =
    alarm &&
    alarm.load &&
    alarm.noti &&
    alarm.noti.filter((item) => item.noti_status === "received").length;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, alarm, setAlarm);
  const onClick = () => {
    if (alarm.popup) {
      // 팝업을 닫는 경우 => alarm.noti를 읽음처리하는 동시에 popup을 닫는다.
      setAlarm({
        noti: alarm.noti.map((item) => ({ ...item, noti_status: "read" })),
        load: true,
        popup: false,
      });
    } else {
      // 팝업을 여는 경우 => (1) 모든 알림 읽음 처리, (2) popup을 연다.
      analytics &&
        logEvent(analytics, "Notification", {
          action: "click noti header",
        });
      api.post(`/user/readNotification`);
      setAlarm({ ...alarm, popup: true });
    }
  };

  useEffect(() => {
    api
      .get("/user/notification", {
        params: {
          page: 1,
          per_page: 10,
        },
      })
      .then((res) => {
        setAlarm({ ...res.data, load: true, popup: false });
      });
  }, [setAlarm]);

  return (
    <div className="cursor-pointer">
      <div className="relative" onClick={onClick}>
        {!alarm.popup && unread && unread > 0 ? (
          <span className="absolute right-0 bg-[#f74545] text-white rounded-full tracking-[-.08em] text-[12px] w-[19px] h-[19px] flex justify-center items-center">
            {unread > 9 ? "9+" : unread}
          </span>
        ) : (
          <></>
        )}
        {children}
      </div>
      {alarm.popup && <AlarmPopup alarm={alarm} unread={unread} wrapperRef={wrapperRef} />}
    </div>
  );
};

export default AlarmHeader;

const AlarmPopup = ({ alarm, unread, wrapperRef }) => {
  const onAlarmClick = (nid) => {
    analytics &&
      logEvent(analytics, "Notification", {
        action: "click noti header article",
      });
    api.post(`/user/readNotification/${nid}`);
  };
  return (
    <div className="alarm_popup" ref={wrapperRef}>
      <div className="w-0 h-0 border-solid border-[15px] border-gray-100 border-r-transparent border-l-transparent border-t-transparent ml-[242px] mt-[-30px]"></div>
      {unread !== 0 && (
        <div className="top">
          읽지 않은 알림&nbsp;
          <span className="purple">{unread > 9 ? "9+" : unread}</span>개
        </div>
      )}
      <div className="middle">
        <ul>
          {alarm.noti.length === 0 ? (
            <div className="flex flex-col justify-center items-center py-8">
              <i className="ii ii-bell-outline text-[50px] text-secondary-600"></i>
              <div className="text-sm">알림이 없습니다.</div>
            </div>
          ) : (
            alarm.noti.map((item, idx) => {
              return (
                <Link
                  to={item.link}
                  onClick={() => onAlarmClick(item.nid)}
                  className="width-max"
                  key={idx}
                >
                  <li className={cls(item.noti_status === "received" ? "unread" : "")}>
                    <div className="flex">
                      <div className="w-6 h-6 rounded-full bg-secondary-200 flex justify-center items-center text-secondary-700 text-base">
                        <i className={notiIcon(item.noti_type)}></i>
                      </div>
                      <span className="text-[14px] ml-3 leading-[18px] max-w-[260px] text-start">
                        {JSON.parse(item.content).map((run, idx) => (
                          <span
                            key={idx}
                            className={
                              run.s === "bold"
                                ? "font-semibold"
                                : run.s === "link"
                                ? "text-primary-800"
                                : run.s === "purple"
                                ? "text-primary-800"
                                : ""
                            }
                          >
                            {run.t}
                          </span>
                        ))}
                        <span className="ml-2 text-gray-400">{dttm(item.reg_dttm)}</span>
                      </span>
                    </div>
                  </li>
                </Link>
              );
            })
          )}
        </ul>
      </div>
      <Link to="/mypage/alarm" className="width-max">
        <div className="bottom">더 많은 알림 보기</div>
      </Link>
    </div>
  );
};
