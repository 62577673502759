import { initializeAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBZURkrEGOt5sjKO89etpJCTM_qZVb552Q",
  authDomain: "moreden-360e9.firebaseapp.com",
  projectId: "moreden-360e9",
  storageBucket: "moreden-360e9.appspot.com",
  messagingSenderId: "656345878710",
  appId: "1:656345878710:web:adafa028e24adb93cb9617",
  measurementId: "G-295GDZJKGC",
};

let analytics = null;

if (import.meta.env.VITE_APP_ARG === "prd") {
  const app = initializeApp(firebaseConfig);
  analytics = initializeAnalytics(app);
}

export default analytics;
